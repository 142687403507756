.footer-container {
  display: flex;
  justify-content: space-around;
}

.vertLine {
  display: none;
}

@media screen and (min-width: 766px) {
  .vertLine {
    display: block;
  }
}

.footer-list ul li {
  margin-bottom: 10px;
}

@media screen and (max-width: 996px) {
  .footer-list,
  .footer-left-content {
    font-size: small;
  }
}
@media screen and (max-width: 744px) {
  .footer-container {
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-left-content {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
  }
  .footer-list {
    width: 100%;
  }
  .footer-list,
  .footer-left-content {
    font-size: large;
  }
  .footer-mobile-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-icon {
    margin: 10px 0px;
    width: 200px;
  }
}
@media screen and (max-width: 528px) {
  .footer-list,
  .footer-left-content {
    font-size: small;
  }
}
@media screen and (max-width: 442px) {
  .footer-list,
  .footer-left-content {
    padding: 16px;
  }
  .footer-icon {
    width: 150px;
  }
}

@media screen and (max-width: 411px) {
  .footer-list,
  .footer-left-content {
    padding: 10px;
    font-size: small;
  }
  .footer-icon {
    width: 100px;
  }
}
@media screen and (max-width: 332px) {
  .footer-list div {
    padding: 0px;
    margin: 0px;
  }
}
