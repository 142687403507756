
@media only screen and (max-width: 900px) {
  .values-container {
    display: flex;
    flex-direction: column;
  }
  .our-values-container {
    width: 100%;
    font-size: 300%;
  }
  .values-content-container {
    width: 100%;
  }
}
@media only screen and (min-width: 250px) and (max-width: 500px) {
  .values-container {
    display: flex;
    flex-direction: column;
    padding: 13%;
  }
  .points-container {
    padding: 10%;
  }
  .our-values-container {
    width: 100%;
    font-size: 250%;
  }
  .values-content-container {
    width: 100%;
  }
  .logo-main-container {
    display: flex;
    flex-direction: column;
  }
}

.footer-color {
  background-color: #191919;
}
@media screen and (max-width: 764px) {
  .footer-color {
    padding: 64px 40px;
  }
}
@media screen and (max-width: 715px) {
  .footer-logo {
    margin-top: 50px;
  }
}

@media screen and (max-width: 562px) {
  .mobile-image {
    width: 120px;
  }
  .mobile-text {
    font-size: small;
    margin-left: 12px;
  }
}
