@media screen and (max-width: 616px) {
  .cards-container {
    width: 14rem;
  }
  .cards-image-container {
    height: 110px;
  }
}

@media screen and (max-width: 552px) {
  .cards-container {
    width: 12rem;
  }
  .cards-image-container {
    height: 90px;
  }
}
@media screen and (max-width: 476px) {
  .cards-container {
    width: 9rem;
  }
  .cards-title {
    font-size: x-large;
    padding: 14px;
  }
}
@media screen and (max-width: 374px) {
    .cards-container {
      width: 8rem;
    }
    .cards-title{
        font-size: large;
        padding: 14px;
    }
  }
// .cards-container:hover{animation: pulse 2s infinite;}
// @keyframes pulse {
//   0%, 100%{transform: scale(1 50%);}
//   50%{transform:scale(1.1 50%)}  
// }

// .cards-container{
//   transition: 0.2s;
// }
.cards-image.grow {
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.5);
  }
}
