.sol-content{
    background: url('../../assest/header/noise.png');
    background-color:#6BC06C;
}
@media screen and (max-width: 300px){
    .multi-button{
        margin: 0px;
        padding: 0px;
    }
}

.cards-idl.grow{
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
}