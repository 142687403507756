.product-container{
    background:url('../../assest/productExperience/greyNoise.png');
    background-color: #191919;
}

@media only screen and (max-width: 768px) {
    .vector-img{
        display: none;
    }
}
@media only screen and (max-width: 480px) {
    .product-text{
        font-size: 24px;
    }
}
