.request-body {
  background: url("../../assest//header/noise.png");
  position: relative;
}
.vector {
  position: absolute;
}
.request-button {
  cursor: pointer;
  padding: 8px 18px;
  font-size: 20px;
}

@media only screen and (max-width: 820px) {
  .hero-line {
    font-size: 36px;
  }
}
@media only screen and (max-width: 610px) {
  .hero-line {
    font-size: 30px;
  }
}
@media only screen and (max-width: 512px) {
  .hero-line {
    font-size: 20px;
  }
}

@media only screen and (max-width: 338px) {
  .hero-line {
    font-size: 16px;
  }
}

@media only screen and (max-width: 610px) {
  .request-button {
    padding: 10px 26px;
  }
  .request-button img {
    width: 25px;
    margin-top: 5px;
  }
}
@media only screen and (max-width: 512px) {
  .request-button {
    padding: 7px 20px;
  }
  .request-button img {
    width: 20px;
    margin-top: 6px;
  }
}
@media only screen and (max-width: 378px) {
  .request-button {
    padding: 5px 16px;
  }
  .request-button img {
    width: 15px;
    margin-top: 7.5px;
  }
}
