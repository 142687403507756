header {
  background: url("../../assest//header/noise.png");
  // position: fixed;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.nav-bar {
  width: 100%;
  align-items: center;
  list-style: none;
  position: fixed;
  background-color: white;
  // background: url("../../assest//header/noise.png");
  z-index: 999;
  top: 0;
  padding: 12px 20px;
}
.menu {
  display: flex;
}
.menu li {
  padding-left: 30px;
}
.menu li a {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  transition: 0.15s ease-in-out;
  position: relative;
  text-transform: uppercase;
}
.menu li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: white;
  transform: 0.15s ease-in-out;
}
.menu li a:hover:after {
  width: 100%;
}
.open-menu,
.close-menu {
  position: absolute;
  cursor: pointer;
  font-size: 1.5em;
  display: none;
}
.open-menu {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.close-menu {
  top: 20px;
  right: 20px;
}
#check {
  display: none;
}
@media (max-width: 768px) {
  .menu {
    background: url("../../assest/header/noise.png");
    justify-content: center;
    width: 60%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 100;
    background-color: #191919;
    transition: all 0.2s ease-in-out;
  }
  // .menu li {margin-top: 40px;}
  .menu li a {
    padding: 10px;
  }
  .open-menu,
  .close-menu {
    display: block;
  }
  #check:checked ~ .menu {
    right: 0;
  }
}
