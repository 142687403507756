.ai-logo{
  width: 300px;
}


@media screen and (max-width: 974px) {
  .ai-main{
    font-size: 40px;
  }
  .ai-logo{
    width: 200px;
  }
  .ai-text{
    font-size: 46px;
  }
}

@media screen and (max-width: 704px) {
  .ai-main{
    font-size: 34px;
  }
  .ai-logo{
    width: 150px;
  }
  .ai-text{
    font-size: 40px;
  }
}

@media screen and (max-width: 480px) {
  .ai{
    width: 70%;
  }
}
