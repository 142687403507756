.provide-container{
    background-color: #1A1A1A;
}
.weprovide-text{
    margin-top: -10%;
}
.provide-image{
    padding-top: 30px;
}
@media screen and (max-width:950px) {
    .weprovide-text{
        font-size: 96px;
    }
}
@media screen and (max-width:650px) {
    .weprovide-text{
        font-size: 72px;
    }
}
@media screen and (max-width:480px) {
    .weprovide-text{
        font-size: 60px;
    }
}
@media screen and (max-width:415px) {
    .weprovide-text{
        font-size: 50px;
    }
}
@media screen and (max-width:376px) {
    .weprovide-text{
        font-size: 46px;
    }
}

@media screen and (max-width:324px) {
    .weprovide-text{
        font-size: 40px;
    }
}
@media screen and (max-width:294px) {
    .weprovide-text{
        font-size: 36px;
    }
}
// .our-services:hover{animation: pulse 2s infinite;}
// @keyframes pulse {
//   0%, 100%{transform: scale(1 70%);}
//   50%{transform:scale(-1.5)}  
// }
.our-service.grow{
    transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.5);
  }
}